.iconDisplaying {
    opacity: 1;
}

.iconNotDisplaying {
    opacity: 0;
}

.highlighted > rect {
    opacity: 1 !important;
    fill: #4b98b4 !important;
}

.highlighted {
    opacity: 1 !important;
}

.seatName {
    font-style: normal;
    font-weight: normal;
    font-size: 15px !important;
    line-height: 1;
    font-family: sans-serif;
    fill: #000000;
    fill-opacity: 1;
    stroke: none;
    stroke-width: 0.716564;
}

.SeatInventoryItemsHint {
    overflow-wrap: anywhere;
    position: absolute;
    background-color: #ffffff;
}

.seatStyleNotBooked {
    fill: green;
    opacity: 0.4;
}

.seatStyleNotBooked:hover {
    opacity: 1
}

.seatStyleBookedByMe {
    fill: blue;
    opacity: 1;
}

.seatStyleBooked {
    fill: red;
    opacity: 1;
}

.seatStyleOrange {
    fill: orangered;
    opacity: 0.8;
}

.seatStyleOrange:hover {
    opacity: 1;
}

.seatStyleYellow {
    fill: yellow;
    opacity: 0.8;
}

.seatStyleYellow:hover {
    opacity: 1;
}

.roomAndIconStyleNotBooked .roomStyleNotBooked {
    fill: green;
    opacity: 0.1;
}

.roomAndIconStyleNotBooked .roomIconStyleNotBooked {
    color: green;
    fill-opacity: 0.4;
}

.roomAndIconStyleNotBooked:hover .roomStyleNotBooked {
    opacity: 0.4;
}

.roomAndIconStyleNotBooked:hover .roomIconStyleNotBooked {
    fill-opacity: 1;
}

.roomAndIconStyleBookedByMe .roomStyleBookedByMe {
    fill: #0059ff;
    opacity: 0.2;
}

.roomAndIconStyleBookedByMe .roomIconStyleBookedByMe {
    color: #0059ff;
    fill-opacity: 0.4;
}

.roomAndIconStyleBookedByMe:hover .roomStyleBookedByMe {
    fill: #0059ff;
    opacity: 0.4;
}

.roomAndIconStyleBookedByMe:hover .roomIconStyleBookedByMe {
    color: #0059ff;
    fill-opacity: 1;
}

.roomAndIconStyleBooked .roomStyleBooked {
    fill: red;
    opacity: 0.2;
}

.roomAndIconStyleBooked .roomIconStyleBooked {
    color: red;
    fill-opacity: 0.4;
}

.roomAndIconStyleBooked:hover .roomStyleBooked {
    fill: red;
    opacity: 0.4;
}

.roomAndIconStyleBooked:hover .roomIconStyleBooked {
    color: red;
    fill-opacity: 1;
}

.roomAndIconStyleOrange .roomStyleOrange {
    fill: orangered;
    opacity: 0.3;
}

.roomAndIconStyleOrange .roomIconStyleOrange {
    color: orangered;
    fill-opacity: 0.4;
}

.roomAndIconStyleOrange:hover .roomStyleOrange {
    opacity: 0.6;
}

.roomAndIconStyleOrange:hover .roomIconStyleOrange {
    fill-opacity: 1;
}

.roomAndIconStyleYellow .roomStyleYellow {
    fill: yellow;
    opacity: 0.1;
}

.roomAndIconStyleYellow .roomIconStyleYellow {
    color: yellow;
    fill-opacity: 0.4;
}

.roomAndIconStyleYellow:hover .roomStyleYellow {
    opacity: 0.6;
}

.roomAndIconStyleYellow:hover .roomIconStyleYellow {
    fill-opacity: 1;
}

.divTransform {
    overflow: hidden;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-content: stretch;
    flex-grow: 1;
}

.divSVG {
    width: fit-content;
    width: -moz-fit-content;
    height: max-content;
    overflow: hidden;
}

.SeatAlreadyBookedHint {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 2px 4px;
    overflow-wrap: anywhere;
    position: absolute;
    background-color: white;
}

.SeatAlreadyBookedHintButton.MuiButton-root {
    margin: 2px 0;
    font-size: 0.7125rem;
}

.tableNumber {
    line-height: 1.25;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    fill: black;
    font-family: sans-serif;
    font-size: 7px;
    text-anchor: middle;
    pointer-events: none;
}

.zoomControl {
    position: fixed;
    bottom: 1em;
    left: 1em;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.resetButton {
    border: 2px grey solid;
    border-radius: 8px;
    margin-bottom: .2em;
    background-color: white;
}

.resetButton:disabled {
    background-color: white;
}

.resetButton:hover {
    background-color: #ededed;
}

.table {
    fill: transparent;
}